import React, { useState } from 'react';
import axios from 'axios';
import logo from '../assets/logoCor.png';
import './css/Login.css';
import { mdiEyeOutline, mdiEyeClosed } from '@mdi/js';
import Icon from '@mdi/react';

const Login = ({ onLoginSuccess }: { onLoginSuccess: (cpfCnpj: string) => void }) => {
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  const validateCpfCnpj = (input: string) => {
    return input.replace(/\D/g, '');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const sanitizedCpfCnpj = validateCpfCnpj(cpfCnpj);

    try {
      const response = await axios.get(
        "https://baserow.tdzain.io/api/database/rows/table/637/",
        {
          params: { user_field_names: true, filter__field_ids: true },
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token NMQz38OYfgfeBuAR3LcngCzRnxgJxiG6",
          },
        }
      );

      const users = response.data.results;
      const user = users.find(
        (user: any) =>
          user.cpfCnpj === sanitizedCpfCnpj &&
          user.password === password // Compare the entire password
      );

      if (user) {
        onLoginSuccess(sanitizedCpfCnpj); // Pass the CPF/CNPJ on success
      } else {
        setError("Invalid CPF/CNPJ or Password.");
      }
    } catch (error) {
      console.error("Error fetching data from Baserow:", error);
      setError("Server error. Please try again later.");
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Logo" />
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="form-group">
          <label htmlFor="cpfCnpj">CPF/CNPJ:</label>
          <input
            type="text"
            id="cpfCnpj"
            value={cpfCnpj}
            onChange={(e) => setCpfCnpj(e.target.value)}
            required
            className="input-field"
            placeholder="Insira o seu CPF/CNPJ"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field"
              placeholder="Insira a sua senha"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="toggle-password"
            >
              {showPassword ? <Icon path={mdiEyeClosed} size={1} /> : <Icon path={mdiEyeOutline} size={1} />}
            </button>
          </div>
        </div>
        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
};

export default Login;
