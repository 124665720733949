import "./App.css";
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import Database from "./pages/Database";
import Login from "./pages/Login";
import Carrinho from "./pages/Carrinho";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState } from "react";

interface CartItem {
  id: number;
  descricao: string;
  quantidade: number;
  razaoSocial: string;
  cpfCnpj: string;
  quantidadeTotal: number;
  quantidadeFaturada: number;
  saldo: number;
}


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userCpfCnpj, setUserCpfCnpj] = useState<string | null>(null);
  const [cart, setCart] = useState<CartItem[]>([]);

  const handleLoginSuccess = (cpfCnpj: string) => {
    setIsLoggedIn(true);
    setUserCpfCnpj(cpfCnpj);
  };

  const handleAddToCart = (newItem: CartItem) => {
    setCart((prevCart) => [...prevCart, newItem]);
  };

  const handleClearCart = () => {
    setCart([]);
  };

  return (
    <div className="app-container">
      {isLoggedIn ? (
        <Router>
          <div className="app">
            <Sidebar />
            <div className="content">
              <Routes>
                <Route path="/" element={<Home cpfCnpj={userCpfCnpj} />} />
                <Route
                  path="/database"
                  element={<Database cpfCnpj={userCpfCnpj} onAddToCart={handleAddToCart} />}
                />
                <Route path="/separacao" element={<Carrinho cart={cart} onClearCart={handleClearCart} />} />
              </Routes>
            </div>
          </div>
        </Router>
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
}

export default App;
