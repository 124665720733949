import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./css/Carrinho.css";

interface CartItem {
  id: number;
  descricao: string;
  quantidade: number;
  razaoSocial: string;
  cpfCnpj: string;
  quantidadeTotal: number;
  quantidadeFaturada: number;
  saldo: number;
}

interface CarrinhoProps {
  cart: CartItem[];
  onClearCart: () => void;
}

const Carrinho: React.FC<CarrinhoProps> = ({ cart, onClearCart }) => {
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);

  const handleCheckout = async () => {
    if (!deliveryDate) {
      alert("Please select a delivery date.");
      return;
    }

    try {
      const response = await axios.post(
        "https://n8n.tdzain.io/webhook/blockbuildtestwebhook", // Replace with your actual webhook URL
        {
          items: cart,
          deliveryDate: deliveryDate.toISOString(), // Send the selected delivery date
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Webhook response:", response.data);
      alert("Checkout successful!");
      onClearCart(); // Clear the cart after successful checkout
    } catch (error) {
      console.error("Error sending webhook:", error);
      alert("Checkout failed. Please try again.");
    }
  };

  return (
    <div className="cart-container">
      <h2>Requisições</h2>
      {cart.length === 0 ? (
        <p>Sem Requisições.</p>
      ) : (
        <>
          <table className="cart-table">
            <thead>
              <tr>
                <th>DESCRIÇÃO</th>
                <th>SALDO</th>
                <th>QUANTIDADE NO CARRINHO</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item) => (
                <tr key={item.id}>
                  <td>{item.descricao}</td>
                  <td>{item.saldo}</td>
                  <td>{item.quantidade}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="datepicker-container">
            <label htmlFor="delivery-date">Escolha a data de entrega:</label>
            <DatePicker
              selected={deliveryDate}
              onChange={(date: Date | null) => setDeliveryDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Selecione uma data"
              id="delivery-date"
            />
          </div>
          <button className="checkout-button" onClick={handleCheckout}>
            Enviar Requisição
          </button>
        </>
      )}
    </div>
  );
};

export default Carrinho;
