import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/Database.css";

interface DatabaseProps {
  cpfCnpj: string | null;
  onAddToCart: (item: CartItem) => void;
}

interface CartItem {
  id: number;
  descricao: string;
  quantidade: number;
  razaoSocial: string;
  cpfCnpj: string;
  quantidadeTotal: number;
  quantidadeFaturada: number;
  saldo: number;
}

const Database: React.FC<DatabaseProps> = ({ cpfCnpj, onAddToCart }) => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [quantities, setQuantities] = useState<{ [key: number]: number }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://baserow.tdzain.io/api/database/rows/table/636/",
          {
            params: { user_field_names: true },
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token rPVr137eHg3GU7vGZWY7es6z378Sdrl1",
            },
          }
        );

        const filteredData = response.data.results.filter(
          (item: any) => item.emi_cpfcnpj === cpfCnpj
        );

        if (filteredData.length === 0) {
          if (
            !response.data.results.some(
              (item: any) => item.emi_cpfcnpj === cpfCnpj
            )
          ) {
            setError("CPF/CNPJ not found in the database.");
          } else {
            setError("No orders found for the specified CPF/CNPJ.");
          }
        } else {
          setData(filteredData);
          setQuantities(
            filteredData.reduce((acc: any, item: any) => {
              acc[item.id] = 0;
              return acc;
            }, {})
          );
        }
      } catch (error) {
        setError("Failed to fetch data");
        console.error("Fetch error:", error);
      }
    };

    if (cpfCnpj) {
      fetchData();
    } else {
      setError("Invalid CPF/CNPJ.");
    }
  }, [cpfCnpj]);

  const handleAddToCart = (item: any, quantity: number) => {
    if (
      quantity > 0 &&
      quantity <= item.IPE_QUANTIDADE - item.IPE_QUANTIDADE_FATURADA
    ) {
      const cartItem: CartItem = {
        id: item.id,
        descricao: item.PRO_DESCRICAO,
        quantidade: quantity,
        razaoSocial: item.PED_EMI_RAZAO,
        cpfCnpj: item.emi_cpfcnpj,
        quantidadeTotal: item.IPE_QUANTIDADE,
        quantidadeFaturada: item.IPE_QUANTIDADE_FATURADA,
        saldo: item.IPE_QUANTIDADE - item.IPE_QUANTIDADE_FATURADA,
      };
      onAddToCart(cartItem); // Add full item details to cart
      setQuantities((prev) => ({ ...prev, [item.id]: 0 }));
    }
  };

  const handleQuantityChange = (itemId: number, quantity: number) => {
    setQuantities((prev) => ({ ...prev, [itemId]: quantity }));
  };

  return (
    <div className="outside">
      {error ? (
        <p className="error-message">{error}</p>
      ) : data.length > 0 ? (
        <>
          <table className="data-table">
            <thead>
              <tr>
                <th>RAZÃO SOCIAL</th>
                <th>CPF/CNPJ</th>
                <th>DESCRIÇÃO</th>
                <th>QUANTIDADE</th>
                <th>QUANTIDADE FATURADA</th>
                <th>SALDO</th>
                <th>SELETOR DE QUANTIDADE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => {
                const saldo =
                  item.IPE_QUANTIDADE - item.IPE_QUANTIDADE_FATURADA;
                const quantity = quantities[item.id] || 0;

                return (
                  <tr key={item.id}>
                    <td data-label="RAZÃO SOCIAL">{item.PED_EMI_RAZAO}</td>
                    <td data-label="CPF/CNPJ">{item.emi_cpfcnpj}</td>
                    <td data-label="DESCRIÇÃO">{item.PRO_DESCRICAO}</td>
                    <td data-label="QUANTIDADE">{item.IPE_QUANTIDADE}</td>
                    <td data-label="QUANTIDADE FATURADA">
                      {item.IPE_QUANTIDADE_FATURADA}
                    </td>
                    <td data-label="SALDO">{saldo}</td>
                    <td data-label="SELETOR DE QUANTIDADE">
                      <input
                        type="number"
                        value={quantity}
                        min="0"
                        max={saldo}
                        onChange={(e) =>
                          handleQuantityChange(item.id, Number(e.target.value))
                        }
                      />
                    </td>
                    <td data-label="">
                      <button
                        onClick={() => handleAddToCart(item, quantity)}
                        disabled={quantity === 0 || quantity > saldo}
                      >
                        Requisitar
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div className="loading">
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};

export default Database;
