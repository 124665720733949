import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import "./css/Home.css";

interface HomeProps {
  cpfCnpj: string | null;
}

const Home: React.FC<HomeProps> = ({ cpfCnpj }) => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [razaoSocial, setRazaoSocial] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://baserow.tdzain.io/api/database/rows/table/636/",
          {
            params: { user_field_names: true },
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token rPVr137eHg3GU7vGZWY7es6z378Sdrl1",
            },
          }
        );

        const filteredData = response.data.results.filter(
          (item: any) => item.emi_cpfcnpj === cpfCnpj
        );

        if (filteredData.length === 0) {
          setError("No orders found for the specified CPF/CNPJ.");
        } else {
          setRazaoSocial(filteredData[0]?.PED_EMI_RAZAO || "User");

          // Group data by PRO_DESCRICAO and sum the saldo
          const groupedData = filteredData.reduce((acc: any, item: any) => {
            const saldo = item.IPE_QUANTIDADE - item.IPE_QUANTIDADE_FATURADA;
            if (acc[item.PRO_DESCRICAO]) {
              acc[item.PRO_DESCRICAO].saldo += saldo;
            } else {
              acc[item.PRO_DESCRICAO] = {
                PRO_DESCRICAO: item.PRO_DESCRICAO,
                saldo: saldo,
              };
            }
            return acc;
          }, {});

          setData(Object.values(groupedData));
        }
      } catch (error) {
        setError("Failed to fetch data");
        console.error("Fetch error:", error);
      }
    };

    if (cpfCnpj) {
      fetchData();
    } else {
      setError("Invalid CPF/CNPJ.");
    }
  }, [cpfCnpj]);

  const chartData = {
    labels: data.map((item) => item.PRO_DESCRICAO),
    datasets: [
      {
        label: 'Saldo',
        data: data.map((item) => item.saldo),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="home-container">
      <header className="hero-section">
        <div className="hero-content">
          <h1>Bem Vindo {razaoSocial}!</h1>
        </div>
      </header>
      <main className="content-section" id="features">
        {error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div className="data-visualization">
            <div className="data-table">
              <table>
                <thead>
                  <tr>
                    <th>Descrição</th>
                    <th>Saldo</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.PRO_DESCRICAO}>
                      <td>{item.PRO_DESCRICAO}</td>
                      <td>{item.saldo}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="data-chart">
              <Bar data={chartData} />
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Home;
