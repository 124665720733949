import React from "react";
import "./css/Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseChimney as faHouseChimneyUser,
  faBuilding,
  faCartPlus
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AsciiCat from "./AsciiCat";
import logo from "../assets/logoBranca.png";
import Carrinho from "../pages/Carrinho";

function Sidebar() {
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <>
      <div className="sidebar">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
        <ul>
          <li className="sidebar-item">
            <div className="icon-column">
              <Link to="/">
                <FontAwesomeIcon icon={faHouseChimneyUser} />
              </Link>
            </div>
            <div className="text-column">
              <Link to="/">Home</Link>
            </div>
          </li>
          <li className="sidebar-item">
            <div className="icon-column">
              <Link to="database">
                <FontAwesomeIcon icon={faBuilding} />
              </Link>
            </div>
            <div className="text-column">
              <Link to="database">Pedidos</Link>
            </div>
          </li>
          <li className="sidebar-item">
            <div className="icon-column">
              <Link to="separacao">
                <FontAwesomeIcon icon={faCartPlus} />
              </Link>
            </div>
            <div className="text-column">
              <Link to="separacao">Requisição</Link>
            </div>
          </li>
        </ul>
      </div>
      <AsciiCat />
    </>
  );
}

export default Sidebar;
